import { Box, Typography } from "@mui/material";
import { Draggable } from "react-beautiful-dnd"

export default function Assignment({assignment, columns, index, employees, setEmployees, employee, setAlertMessage}) {
    const shownColumns = columns.filter(column => column.shown);
    return (
        <Draggable key={assignment?.['3']['value']} draggableId={String(assignment?.['3']['value'])} index={index}>
            {(provided, snapshot) => {
                return (
                    <div 
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`flex flex-row h-8 w-full border-b border-gray-200 bg-white relative`}
                    >
                        { 
                            shownColumns.map((column, index) => (
                                <Box 
                                    key={index}
                                    title={String(assignment?.[column?.field_id])}
                                    sx={{
                                        borderRight: "1px solid #E5E7EB",
                                        height: "100%",
                                        minWidth: column.width,
                                        maxWidth: column.width,
                                        display: "flex",
                                        justifyContent: column.align,
                                        alignItems: "center",
                                        padding: "0px 8px",
                                        boxSizing: "border-box",
                                        backgroundColor: "#fff",
                                        position: column.id === 1 ? "sticky" : "relative",
                                        left: column.id === 1 ? "0px" : "auto",
                                        zIndex: column.id === 1 ? "100" : "0",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "12px", 
                                            color: "rgb(51, 51, 51)",
                                            textOverflow: "ellipsis", 
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                        }}
                                    >
                                        {assignment?.[column?.field_id]['value']}
                                    </Typography>
                                </Box>
                            ))
                        }
                    </div>
                )
            }}
        </Draggable>
    )
}