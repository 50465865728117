import { Typography, Link } from "@mui/material";
import { LuMessageSquare } from "react-icons/lu";

export default function SalesButton() {
    return (
        <Link
            href="https://synctivate.com/contact/"
            target="_blank"
            sx={{
                height: '2rem',
                borderRadius: '1rem',
                color: 'hsl(0,0%,30%)',
                backgroundColor: 'white',
                px: '0.75rem',
                fontSize: '13px',
                textTransform: 'none',
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                },
            }}
        >
            <LuMessageSquare className='text-[hsl(0,0%,30%)] h-4 w-4' />
            <Typography sx={{fontSize: '13px', ml: '0.25rem', color: 'hsl(0,0%,30%)', lineHeight: 'normal'}}>Contact Sales</Typography>
        </Link>
    );
}