import { useState } from "react";

export function useFilters() {
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState([
        {
            column: 1,
            field_id: 3,
            value: "",
        },
        {
            column: 2,
            field_id: 8,
            value: "",
        },
        {
            column: 3,
            field_id: 9,
            value: "",
        },
        {
            column: 4,
            field_id: 10,
            value: "",
        },
        {
            column: 5,
            field_id: 11,
            value: "",
        },
        {
            column: 6,
            field_id: 12,
            value: "",
        },
        {
            column: 7,
            field_id: 13,
            value: "",
        },
        {
            column: 8,
            field_id: 14,
            value: "",
        },
        {
            column: 9,
            field_id: 15,
            value: "",
        },
    ]);

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    }

    return {showFilters, toggleFilters, filters, setFilters};
}