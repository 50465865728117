
import { TextField } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input'
import { useState } from 'react'

export default function CompanyDetails({ companyData, setCompanyData }) {

    const handleUpdate = (key, value) => {
        setCompanyData({ ...companyData, [key]: value })
    }


    const [phone1, setPhone1] = useState("+1")
    const handlePhone1Change = (value) => {
        setPhone1(value)
        setCompanyData({ ...companyData, phone1: value })
    }
    const [phone2, setPhone2] = useState("+1")
    const handlePhone2Change = (value) => {
        setPhone2(value)
        setCompanyData({ ...companyData, phone2: value })
    }

    return (
        <div>
            <TextField
                margin="normal"
                size="small"
                fullWidth
                id="company_name"
                label="Company Name"
                name="company_name"
                autoComplete="company_name"
                autoFocus
                onChange={(e) => handleUpdate('company_name', e.target.value)}
            />
            <TextField
                margin="normal"
                size="small"
                fullWidth
                id="address_street"
                label="Address Street"
                name="address_street"
                autoComplete="address_street"
                onChange={(e) => handleUpdate('address_street', e.target.value)}
            />
            <TextField
                margin="normal"
                size="small"
                fullWidth
                id="address_street_2"
                label="Address Street 2"
                name="address_street_2"
                autoComplete="address_street_2"
                onChange={(e) => handleUpdate('address_street_2', e.target.value)}
            />
            <div className="w-full flex gap-4">
                <TextField
                    margin="normal"
                    size="small"
                    fullWidth
                    id="address_city"
                    label="Address City"
                    name="address_city"
                    autoComplete="address_city"
                    onChange={(e) => handleUpdate('address_city', e.target.value)}
                />
                <TextField
                    margin="normal"
                    size="small"
                    fullWidth
                    id="address_state"
                    label="Address State"
                    name="address_state"
                    autoComplete="address_state"
                    onChange={(e) => handleUpdate('address_state', e.target.value)}
                />
                <TextField
                    margin="normal"
                    size="small"
                    fullWidth
                    id="address_zip"
                    label="Address Zip"
                    name="address_zip"
                    autoComplete="address_zip"
                    onChange={(e) => handleUpdate('address_zip', e.target.value)}
                />
            </div>
            <MuiTelInput
                value={phone1}
                onChange={handlePhone1Change}
                margin="normal"
                size="small"
                fullWidth
                id="phone_1"
                label="Phone 1"
                name="phone_1"
                autoComplete="phone_1"
            />
            <MuiTelInput
                value={phone2}
                onChange={handlePhone2Change}
                margin="normal"
                size="small"
                fullWidth
                id="phone_2"
                label="Phone 2"
                name="phone_2"
                autoComplete="phone_2"
            />
            <TextField
                margin="normal"
                size="small"
                fullWidth
                id="email_address"
                label="Email Address"
                name="email_address"
                autoComplete="email_address"
                inputProps={{ itemType: 'email' }}
                onChange={(e) => handleUpdate('email', e.target.value)}
            />
            <TextField
                margin="normal"
                size="small"
                fullWidth
                id="website"
                label="Website"
                name="website"
                autoComplete="website"
                onChange={(e) => handleUpdate('website', e.target.value)}
            />
        </div>
    )
}