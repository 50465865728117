import { Button } from "@mui/material";

export default function SidenavButton({ children, onClick }) {
  return (
    <Button
        onClick={onClick}
        sx={{
            borderRadius: '4px',
            color: 'hsl(0,0%,30%)',
            backgroundColor: 'white',
            px: '0.75rem',
            fontSize: '13px',
            textTransform: 'none',
            alignItems: 'center',
            justifyContent: 'flex-start',
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
            },
        }}
    >
      {children}
    </Button>
  );
}