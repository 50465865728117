import RoutingPreview from '../../assets/routing-preview.png';
import OrganizerPreview from '../../assets/organizer-preview.png';
import DashboardPreview from '../../assets/dashboard-preview.png';
import TimesheetPreview from '../../assets/timesheet-preview.png';
import CompanyFormPreview from '../../assets/company-form-preview.png';
import SchedulerPreview from '../../assets/scheduler-preview.png';
import DemoCard from './DemoCard';

export default function IntegrationSection() {
    return (
        <div className='flex flex-col flex-1 pt-8 pb-4 px-12'>

            <h1 className="text-2xl font-bold mb-4 mx-auto">Custom Demos</h1>

            <div className="grid grid-cols-3 gap-4 w-full py-4">
                <DemoCard 
                    onClick={() => { window.open('/form-demo', '_blank') }}
                    preview={CompanyFormPreview}
                    title={'Custom Forms'}
                    industry={'TOOLS'}
                    description={'Enter data on cleaner, custom forms that sync directly with your app'}
                />
                <DemoCard 
                    onClick={()=> {window.open('/dashboard/create/','_blank')}}
                    preview={DashboardPreview}
                    title={'Custom Dashboards'}
                    industry={'TOOLS'}
                    description={'Easily visualize data from any source'}
                />
                <div 
                    onClick={()=> {window.open('/routing/','_blank')}}
                    className="flex flex-col shadow-[0_0_1px_rgba(0,0,0,0.32),0_0_2px_rgba(0,0,0,0.08),0_1px_3px_rgba(0,0,0,0.08)] rounded-md hover:shadow-[0_0_1px_rgba(0,0,0,0.2),0_0_2px_rgba(0,0,0,0.08),0_3px_4px_rgba(0,0,0,0.03),0_3px_16px_rgba(0,0,0,0.06)] cursor-pointer"
                >
                    <img src={RoutingPreview} alt="Routing Tool Preview" className="w-full h-48 object-cover rounded-t-md border-b border-gray-200" />
                    <div className="p-4 flex flex-col">
                        <p className="text-xs">TOOLS</p>
                        <h1 className="my-3 text-[22px] font-semibold">Route Planner</h1>
                        <p className="text-base">
                            Plan your routes and optimize your travel time
                        </p>
                    </div>
                </div>
                <div 
                    onClick={()=> {window.open('https://www.youtube.com/watch?v=0pUOoffr2Tg&feature=youtu.be','_blank')}}
                    className="flex flex-col shadow-[0_0_1px_rgba(0,0,0,0.32),0_0_2px_rgba(0,0,0,0.08),0_1px_3px_rgba(0,0,0,0.08)] rounded-md hover:shadow-[0_0_1px_rgba(0,0,0,0.2),0_0_2px_rgba(0,0,0,0.08),0_3px_4px_rgba(0,0,0,0.03),0_3px_16px_rgba(0,0,0,0.06)] cursor-pointer"
                >
                    <img src={SchedulerPreview} alt="Routing Tool Preview" className="w-full h-48 object-cover rounded-t-md border-b border-gray-200" />
                    <div className="p-4 flex flex-col">
                        <p className="text-xs">TOOLS</p>
                        <h1 className="my-3 text-[22px] font-semibold">Sync Scheduler</h1>
                        <p className="text-base">
                            Custom Scheduling and Routing UI with Map Integration
                        </p>
                    </div>
                </div>
                <div 
                    onClick={()=> {window.open('/organizer','_blank')}}
                    className="flex flex-col shadow-[0_0_1px_rgba(0,0,0,0.32),0_0_2px_rgba(0,0,0,0.08),0_1px_3px_rgba(0,0,0,0.08)] rounded-md hover:shadow-[0_0_1px_rgba(0,0,0,0.2),0_0_2px_rgba(0,0,0,0.08),0_3px_4px_rgba(0,0,0,0.03),0_3px_16px_rgba(0,0,0,0.06)] cursor-pointer"
                >
                    <img src={OrganizerPreview} alt="Routing Tool Preview" className="w-full h-48 object-cover rounded-t-md border-b border-gray-200" />
                    <div className="p-4 flex flex-col">
                        <p className="text-xs">TOOLS</p>
                        <h1 className="my-3 text-[22px] font-semibold">Task Organizer</h1>
                        <p className="text-base">
                            Effortlessly organize tasks based on priority and team availability
                        </p>
                    </div>
                </div>
                <div 
                    onClick={()=> {window.open('/timecards/create/','_blank')}}
                    className="flex flex-col shadow-[0_0_1px_rgba(0,0,0,0.32),0_0_2px_rgba(0,0,0,0.08),0_1px_3px_rgba(0,0,0,0.08)] rounded-md hover:shadow-[0_0_1px_rgba(0,0,0,0.2),0_0_2px_rgba(0,0,0,0.08),0_3px_4px_rgba(0,0,0,0.03),0_3px_16px_rgba(0,0,0,0.06)] cursor-pointer"
                >
                    <img src={TimesheetPreview} alt="Routing Tool Preview" className="w-full h-48 object-cover rounded-t-md border-b border-gray-200" />
                    <div className="p-4 flex flex-col">
                        <p className="text-xs">TOOLS</p>
                        <h1 className="my-3 text-[22px] font-semibold">Timesheet Entry</h1>
                        <p className="text-base">
                            Enter your time and submit your timesheet with ease
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}