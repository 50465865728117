import { useState, useEffect } from "react"
import MultiPageForm from "./MultiPageForm";
import CompanyDetails from "./CompanyDetails";
import ContactsPage from "./ContactsPage";
import PageHeader from "./PageHeader";
import Loading from "../../shared/components/Loading";
import AlertMessage from "../../shared/components/AlertMessage";


export default function CompanyForm() {
    const [submitting, setSubmitting] = useState(false)
    const [companyData, setCompanyData] = useState({
        company_name: "",
        address_street: "",
        address_street_2: "",
        address_city: "",
        address_state: "",
        address_zip: "",
        phone1: "",
        phone2: "",
        email: "",
        website: "",
    })
    const [contacts, setContacts] = useState([])
    const [message, setMessage] = useState('')

    const submitForm = () => {
        setSubmitting(true)
    }
    useEffect(() => {
        if (submitting) {
            console.log(companyData)
            contacts.forEach(contact => {
                contact.rid = contact.id + 1
                console.log(contact)
            })
            const timer = setTimeout(() => {
                setSubmitting(false)
                setContacts([])
                setMessage(`Company ${companyData.company_name} and ${contacts.length} contacts added to your app`)
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [submitting, companyData, contacts])

    return (
        <>
            <AlertMessage message={message} setMessage={setMessage} />
            {submitting ? (
                <Loading loading={submitting} />
            ) : (
                    <>
                        <PageHeader />
                        <MultiPageForm label={"Company Form"} initialStep={1} totalSteps={2} onSubmit={submitForm}>
                            <CompanyDetails
                                companyData={companyData}
                                setCompanyData={setCompanyData}
                            />
                            <ContactsPage
                                contacts={contacts}
                                setContacts={setContacts}
                            />
                        </MultiPageForm>
                    </>
            )}
        </>
    )
}



