import { useState, useEffect } from "react";
import OnePagerCard from "./OnePagerCard";

export default function IndustryOnePagersSection() {

    const [onePagers, setOnePagers] = useState([]);

    useEffect(() => {

        async function fetchOnePagers() {
            const response = await fetch('https://api.quickbase.com/v1/records/query',
                {
                method: 'POST',
                headers: {
                    'QB-Realm-Hostname': 'synctivate.quickbase.com',
                    'Authorization': 'QB-USER-TOKEN b7nsa7_nyiv_0_bh86apvb5626whctbxrrc4t4bqa',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "from": "bt9cqxzmc",
                    "select": [
                        3, // Record ID
                        6, // Industry
                        7, // Description
                        15, // iFrame
                    ],
                    "where": "{3.GT.0}",                  
                })
            })
            const data = await response.json();
            console.log(data);
            setOnePagers(data?.data);
        }

        fetchOnePagers();
        
    }
    , []);

    return (
        <div className='flex flex-col flex-1 pb-8 px-12'>

            <h1 className="text-2xl font-bold mb-4 mx-auto">One Pagers</h1>

            <div className="grid grid-cols-3 gap-4 py-4">
                {onePagers.map((onePager) => (
                    <OnePagerCard
                        key={onePager?.['3']?.value}
                        industry={onePager?.['6']?.value}
                        title={onePager?.['6']?.value}
                        description={onePager?.['7']?.value}
                        link={'/one-pagers/' + onePager?.['3']?.value}
                    />
                ))}
            </div>
        </div>
    )
}