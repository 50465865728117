export default function OnePagerCard({ industry, title, description, link }) {
    return (
        <div className="
            flex
            flex-col
            shadow-[0_0_1px_rgba(0,0,0,0.32),0_0_2px_rgba(0,0,0,0.08),0_1px_3px_rgba(0,0,0,0.08)]
            hover:shadow-[0_0_1px_rgba(0,0,0,0.2),0_0_2px_rgba(0,0,0,0.08),0_3px_4px_rgba(0,0,0,0.03),0_3px_16px_rgba(0,0,0,0.06)]
            rounded-md
            cursor-pointer
            "
        >
            <div className="p-6 flex flex-col flex-1">
                <p className="text-xs uppercase text-gray-500">{industry}</p>
                <h2 className="my-3 text-lg font-semibold">{title}</h2>
                <p className="text-sm mb-4">{description}</p>
                <a href={link} target="_blank" rel="noopener noreferrer"
                   className="mt-auto bg-blue-500 text-white text-center py-2 rounded-md">
                    View PDF
                </a>
            </div>
        </div>
    );
}