import SidenavToggle from './buttons/SidenavToggle';
import Logo from '../../../assets/navbar-banner.jpeg';
import SalesButton from './buttons/SalesButton';
import { Link, Typography } from '@mui/material';

export default function Navbar() {
  return (
    <header className="flex min-h-16 items-center w-full box-border shadow-[0_0_1px_rgba(0,0,0,0.32),0_0_2px_rgba(0,0,0,0.08),0_1px_3px_rgba(0,0,0,0.08)] z-40">
        <nav className="flex items-center box-border w-full pl-2 pr-4">
            <div className="flex flex-1 items-center box-border">
                <SidenavToggle />
                <Link href="https://synctivate.com/" target="_blank" sx={{ml: '0.75rem'}}>
                    <img src={Logo} alt="Logo" className="h-8" />
                </Link>
            </div>
            <div className="flex flex-1 items-center justify-end box-border">
                <Typography sx={{fontSize: '13px', color: 'hsl(0,0%,30%)', lineHeight: 'normal', mr: '0.5rem'}}>+1 802-988-0034</Typography>
                <SalesButton />
            </div>
        </nav>
    </header>
  );
}