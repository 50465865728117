import { useContext } from 'react';
import {IconButton} from '@mui/material';
import { IoIosMenu } from "react-icons/io";
import HomeContext from '../../context/HomeContext';

export default function SidenavToggle() {
  const { toggleSidenav } = useContext(HomeContext);
  return (
      <IconButton
        onClick={() => {toggleSidenav()}}
        sx={{
            height: '2rem',
            p: '0.25rem',
        }}
      >
        <IoIosMenu className='text-gray-400 h-full w-full' />
      </IconButton>
  );
}