export default function ProgressButton({ children, onClick, inactive = false }) {
    const bg = inactive ? "bg-gray-300" : "bg-[#4362a1]";
    return (
        <button
            onClick={onClick}
            className={`${bg} text-white font-bold h-8 w-8 rounded-full z-10`}
        >
            {children}
        </button>
    )
}