import Logo from '../../../assets/synctivate-large-logo.png';


export default function PageHeader() {
    return (
        <div className="h-12 bg-white flex items-center px-2 w-full z-[999] box-border fixed top-0 left-0
            shadow-[0_0_1px_rgba(0,0,0,0.32),0_0_2px_rgba(0,0,0,0.08),0_1px_3px_rgba(0,0,0,0.08)]">

            <img src={Logo} alt="Synctivate Logo" className='h-7 w-auto' />
        </div>
    )
}