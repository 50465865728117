import AddIcon from '@mui/icons-material/Add';

export default function PlusButton({ onClick }) {
    return (
        <button
            onClick={onClick}
            // clear background, square, primary color text
            className='text-[#4362a1] h-8 w-8 rounded-full'
        >
            <AddIcon />
        </button>
    )
}