import { useContext } from "react";
import SidenavButton from "./buttons/SidenavButton";
import { Typography, Divider } from "@mui/material";
import { IoCalendarClearOutline, IoMapOutline } from "react-icons/io5";
import { TbClockPlus } from "react-icons/tb";
import { MdAddBusiness, MdDataThresholding } from "react-icons/md";
import HomeContext from "../context/HomeContext";
export default function Sidenav() {
    
    const { sidenavOpen } = useContext(HomeContext);

    if (!sidenavOpen) {
        return null;
    }

    return (
        <div className="min-w-[300px] max-w-[300px] border-r border-gray-200 p-3 bg-white h-full">
            <div className="flex flex-col w-full">
                <Typography sx={{fontWeight: "600", color: "rgb(51,51,51)", fontSize: '15px', mb: '0.5rem'}}>Tools</Typography>
                <SidenavButton
                    onClick={() => { window.open('/form-demo', '_blank') }}
                >
                    <div className="h-6 w-6 rounded-md bg-[#166EE1] flex items-center justify-center">
                        <MdAddBusiness className='text-white h-4 w-4' />
                    </div>
                    <Typography sx={{fontSize: '13px', fontWeight: '500', color: 'rgb(51,51,51)', lineHeight: 'normal', ml: '0.5rem'}}>Custom Forms</Typography>
                </SidenavButton>
                <SidenavButton
                    onClick={()=> {window.open('/dashboard/create/','_blank')}}
                >
                    <div className="h-6 w-6 rounded-md bg-[#166EE1] flex items-center justify-center">
                        <MdDataThresholding className='text-white h-4 w-4' />
                    </div>
                    <Typography sx={{fontSize: '13px', fontWeight: '500', color: 'rgb(51,51,51)', lineHeight: 'normal', ml: '0.5rem'}}>Custom Dashboards</Typography>
                </SidenavButton>
                <SidenavButton
                    onClick={()=> {window.open('/routing/','_blank')}}
                >
                    <div className="h-6 w-6 rounded-md bg-[#166EE1] flex items-center justify-center">
                        <IoMapOutline className='text-white h-4 w-4' />
                    </div>
                    <Typography sx={{fontSize: '13px', fontWeight: '500', color: 'rgb(51,51,51)', lineHeight: 'normal', ml: '0.5rem'}}>Route Planner</Typography>
                </SidenavButton>
                <SidenavButton
                    onClick={()=> {window.open('https://www.youtube.com/watch?v=0pUOoffr2Tg&feature=youtu.be','_blank')}}
                >
                    <div className="h-6 w-6 rounded-md bg-[#166EE1] flex items-center justify-center">
                        <IoCalendarClearOutline className='text-white h-4 w-4' />
                    </div>
                    <Typography sx={{fontSize: '13px', fontWeight: '500', color: 'rgb(51,51,51)', lineHeight: 'normal', ml: '0.5rem'}}>Sync Scheduler</Typography>
                </SidenavButton>
                <SidenavButton
                    onClick={()=> {window.open('/organizer','_blank')}}
                >
                    <div className="h-6 w-6 rounded-md bg-[#166EE1] flex items-center justify-center">
                        <IoCalendarClearOutline className='text-white h-4 w-4' />
                    </div>
                    <Typography sx={{fontSize: '13px', fontWeight: '500', color: 'rgb(51,51,51)', lineHeight: 'normal', ml: '0.5rem'}}>Task Organizer</Typography>
                </SidenavButton>
                <SidenavButton
                    onClick={()=> {window.open('/timecards/create/','_blank')}}
                >
                    <div className="h-6 w-6 rounded-md bg-[#166EE1] flex items-center justify-center">
                        <TbClockPlus className='text-white h-4 w-4' />
                    </div>
                    <Typography sx={{fontSize: '13px', fontWeight: '500', color: 'rgb(51,51,51)', lineHeight: 'normal', ml: '0.5rem'}}>Timesheet Entry</Typography>
                </SidenavButton>
            </div>
            <Divider sx={{my: '1rem'}} />
        </div>
    );
}