import TableHeader from "./TableHeader";
import Columns from "./Columns";
import Loading from "../../shared/components/Loading";
import Employee from "./Employee";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useState, useEffect } from "react";
import AlertMessage from "../../shared/components/AlertMessage";
import { useColumns } from "../../shared/hooks/useColumns";
import { useFilters } from "../../shared/hooks/useFilters";
import { handleDrop } from "../../shared/hooks/handleDrop";
import Placeholder from "./Placeholder";
import Assignment from "./Assignment";

export default function SchedulerDemo() {
    const [saving, setSaving] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [employees, setEmployees] = useState([]);
    const {columns, setColumns, totalWidth} = useColumns();
    const { showFilters, toggleFilters, filters, setFilters } = useFilters();
    const [employeesLoading, setEmployeesLoading] = useState(true);
    const [assignmentsLoading, setAssignmentsLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            setEmployeesLoading(true);
            setAssignmentsLoading(true);
            
            try {
                const employeesResponse = await fetch('https://api.quickbase.com/v1/records/query', {
                    method: 'POST',
                    headers: {
                        'Authorization': 'QB-USER-TOKEN b7nsa7_nyiv_0_bh86apvb5626whctbxrrc4t4bqa',
                        'QB-Realm-Hostname': 'synctivate.quickbase.com',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "from": "btye8bu3n",
                        "select": [3, 8],
                        "where": "{3.GT.0}",
                    }),
                });
                const employeesData = await employeesResponse.json();
    
                const assignmentsResponse = await fetch('https://api.quickbase.com/v1/records/query', {
                    method: 'POST',
                    headers: {
                        'Authorization': 'QB-USER-TOKEN b7nsa7_nyiv_0_bh86apvb5626whctbxrrc4t4bqa',
                        'QB-Realm-Hostname': 'synctivate.quickbase.com',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "from": "btye8bu5f",
                        "select": [3, 6, 8, 9, 10, 11, 12, 13, 14, 15],
                        "where": "{3.GT.0}",
                    }),
                });
                const assignmentsData = await assignmentsResponse.json();
    
                // Now that both requests are completed, update the employees with their assignments
                const updatedEmployees = employeesData.data.map(employee => {
                    const employeeAssignments = assignmentsData.data.filter(assignment => assignment['6']['value'] === employee['3']['value']);
                    return {
                        ...employee,
                        assignments: employeeAssignments
                    };
                });
    
                setEmployees(updatedEmployees); // Update the state once with all necessary data
            } catch (error) {
                console.error("Failed to fetch data", error);
            } finally {
                setEmployeesLoading(false);
                setAssignmentsLoading(false);
            }
        }
    
        fetchData();
    }, []); // Empty dependency array ensures this effect runs only once after the component mounts
    
    if (employeesLoading || assignmentsLoading || saving) return <Loading loading={employeesLoading || assignmentsLoading || saving} />;

    const filteredEmployees = employees.filter((employee) => {
        // No active search filter
        if (searchTerm === "") return true;
        return employee?.['8']['value'].toLowerCase().includes(searchTerm.toLowerCase());
    })

    return (
        <DragDropContext onDragEnd={(result) => {handleDrop(result, employees, setEmployees)}}>
            {/* Parent Container */}
            <div className={`absolute top-0 bottom-0 left-0 right-0 mt-12`}>

                {/* Table Header */}
                <TableHeader columns={columns} setColumns={setColumns} employees={employees} searchTerm={searchTerm} setSearchTerm={setSearchTerm} setSaving={setSaving} setAlertMessage={setAlertMessage} showFilters={showFilters} toggleFilters={toggleFilters}/>

                {/* Records */}
                <div className="bg-white flex flex-row h-full box-border">

                    {/* Assigned Records */}
                    <div className="overflow-auto flex flex-col relative">

                        {/* Columns */}
                        <Columns width={totalWidth} showFilters={showFilters} filters={filters} setFilters={setFilters} columns={columns} />

                        {/* Table Body */}
                        <div style={{width: totalWidth + 'px'}}>
                            {
                                filteredEmployees?.map((employee, idx) => (
                                    <div className="flex flex-col" key={idx}>
                                        <Employee employee={employee} />
                                        <Droppable droppableId={String(employee?.['3']['value'])} key={employee?.['3']['value']}>
                                            {(provided, snapshot) => {
                                                return (
                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        className="flex flex-row w-full flex-wrap bg-white border-t border-gray-200"
                                                    >
                                                        <Assignments employee={employee} columns={columns} filters={filters} employees={employees} setEmployees={setEmployees} setAlertMessage={setAlertMessage}/>
                                                        {provided.placeholder}
                                                    </div>
                                                )
                                            }}
                                        </Droppable>
                                    </div>
                                ))
                            }
                        </div>

                        <AlertMessage message={alertMessage} setMessage={setAlertMessage} />

                    </div>

                </div>

            </div>
        </DragDropContext>
    )
}

function Assignments({employee, columns, filters, employees, setEmployees, setAlertMessage}) {
    const filteredAssignments = (employee) => {
        // If each filter object's value property within filter is empty, return the assignment
        if (filters.every((filter) => filter.value === "")) return employee?.assignments || [];
        // Else, filter all properties on the assignment whose matching filter value is not empty
        return employee?.assignments?.filter((assignment) => {
            return filters.every((filter) => {
                if (filter.value === "") return true; // Skip empty filters
                const assignmentValue = assignment[filter.field_id]['value'];
                if (typeof assignmentValue === 'string') {
                    return assignmentValue.toLowerCase().includes(filter.value.toLowerCase());
                } 
                if (typeof assignmentValue === 'number') {
                    return assignmentValue.toString().includes(filter.value);
                }
                return true; // or some other default behavior for unsupported types
            });
        });
    }

    if (!employee.assignments || filteredAssignments(employee).length === 0) return (
        <Placeholder />
    )

    return (
        filteredAssignments(employee).map((assignment, idx) => (
            <Assignment key={assignment?.['3']['value']} assignment={assignment} columns={columns} index={idx} employees={employees} setEmployees={setEmployees} employee={employee} setAlertMessage={setAlertMessage}/>
        ))
    )
}