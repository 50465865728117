import PlusButton from "./buttons/PlusButton";
import DeleteButton from "./buttons/DeleteButton";
import { TextField, FormControlLabel, Checkbox } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input'
import { useState, useEffect } from "react";

export default function ContactsPage({ contacts, setContacts }) {
    const [contactCtr, setContactCtr] = useState(0)
    const addContact = () => {
        setContactCtr(contactCtr + 1)
        setContacts([...contacts, {
            id: contactCtr,
            name: '',
            email: '',
            phone1: '+1',
            phone2: '+1',
            title: '',
            primary: false
        }])
    }
    const deleteContact = (id) => {
        setContacts(contacts.filter(contact => contact.id !== id))
    }

    return (
        <div className="flex flex-col">
            <div className="flex gap-4 items-center">

                Add Contacts
                <PlusButton onClick={addContact} />
            </div>
            {contacts.map((contact, index) => (
                <ContactRow key={index} index={contact.id} deleteRow={deleteContact} contactList={contacts} setContactList={setContacts} />
            ))}
        </div>
    )
}

function ContactRow({ index, deleteRow, contactList, setContactList }) {
    const contact = contactList.find(contact => contact.id === index)
    const updateContactList = (fname, value) => {
        let contact = contactList.find(contact => contact.id === index)
        contact[fname] = value
        setContactList([...contactList])
    }

    const [phone1, setPhone1] = useState(contact.phone1)
    const [phone2, setPhone2] = useState(contact.phone2)
    const handlePhone1Change = (value) => {
        setPhone1(value)
        updateContactList('phone1', value)
    }
    const handlePhone2Change = (value) => {
        setPhone2(value)
        updateContactList('phone2', value)
    }
    useEffect(() => {
        setPhone1(contact.phone1)
        setPhone2(contact.phone2)
    }, [contact])


    return (
        <div className="flex gap-4 items-center">
            <TextField
                onChange={(e) => updateContactList('name', e.target.value)}
                margin="normal"
                size="small"
                fullWidth
                id={`contact_${index}_name`}
                label="Contact Name"
                name={`contact_${index}_name`}
                autoComplete={`contact_${index}_name`}
                value={contact.name}
            />
            <TextField
                onChange={(e) => updateContactList('email', e.target.value)}
                margin="normal"
                size="small"
                fullWidth
                id={`contact_${index}_email`}
                label="Contact Email"
                name={`contact_${index}_email`}
                autoComplete={`contact_${index}_email`}
                value={contact.email}
            />
            <MuiTelInput
                onChange={(value) => handlePhone1Change(value)}
                margin="normal"
                size="small"
                fullWidth
                id={`contact_${index}_phone_1`}
                label="Contact Phone 1"
                name={`contact_${index}_phone_1`}
                autoComplete={`contact_${index}_phone_1`}
                value={phone1}
            />
            <MuiTelInput
                onChange={(value) => handlePhone2Change(value)}
                margin="normal"
                size="small"
                fullWidth
                id={`contact_${index}_phone_2`}
                label="Contact Phone 2"
                name={`contact_${index}_phone_2`}
                autoComplete={`contact_${index}_phone_2`}
                value={phone2}
            />
            <TextField
                onChange={(e) => updateContactList('title', e.target.value)}
                margin="normal"
                size="small"
                fullWidth
                id={`contact_${index}_title`}
                label="Contact Title"
                name={`contact_${index}_title`}
                autoComplete={`contact_${index}_title`}
                value={contact.title}
            />
            <FormControlLabel
                control={<Checkbox />}
                onChange={(e) => updateContactList('primary', e.target.checked)}
                label="Is Primary Contact?"
                checked={contact.primary}
            />
            <DeleteButton hidden={!!contact.rid} onClick={() => deleteRow(index)} />
        </div>
    )
}