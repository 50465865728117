import ProgressButton from "./buttons/ProgressButton";

export default function ProgressBar({ totalSteps = 3, currentStep = 1, setCurrentStep }) {
    return (
        <div className="w-full relative flex justify-between">
            <ProgressBarLine currentStep={currentStep} totalSteps={totalSteps} />
            {[...Array(totalSteps)].map((_, index) => (
                <ProgressButton onClick={() => setCurrentStep(index + 1)} key={index} inactive={index >= currentStep}>{index + 1}</ProgressButton>
            ))}
        </div>
    )
}

function ProgressBarLine({ currentStep = 1, totalSteps = 3 }) {
    const width = ((currentStep - 1) / (totalSteps - 1)) * 99
    return (
        // center absolute position
        <div className=" h-1 bg-gray-300 absolute inset-x-1 bottom-1/2 translate-y-1/2">
            <div className="w-full h-1 bg-[#4362a1] absolute transition-all" style={{ width: `${width}%` }} />
        </div>
    )
}