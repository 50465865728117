import { useState } from "react"
import ToggleColumnsButton from "./buttons/ToggleColumnsButton"
import ToggleFilterButton from "./buttons/ToggleFilterButton"
import ToggleSearchButton from "./buttons/ToggleSearchButton"
import Searchbar from "./inputs/Searchbar"
import Logo from '../../../assets/synctivate-large-logo.png';

export default function TableHeader({employees, searchTerm, setSearchTerm, setAlertMessage, setSaving, showFilters, toggleFilters, columns, setColumns}) {
    const [searchShown, setSearchShown] = useState(true);
    return (
        <div className="h-12 bg-white flex items-center px-2 w-full z-[999] box-border fixed top-0 left-0">

            <img src={Logo} alt="Synctivate Logo" className='h-7 w-auto' />
            
            <Searchbar searchShown={searchShown} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

            <div className={`flex flex-row justify-center items-center h-full ml-0`}>
                <ToggleSearchButton searchShown={searchShown} setSearchShown={setSearchShown} />
                <ToggleFilterButton showFilters={showFilters} toggleFilters={toggleFilters} />
                <ToggleColumnsButton columns={columns} setColumns={setColumns} />
            </div>

        </div>
    )
}