import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


export default function DeleteButton({ hidden, onClick }) {
    return (
        <button
            onClick={onClick}
            // clear background, square, primary color text
            className='text-[#4362a1] h-8 w-8 rounded-full'
            hidden={hidden}
        >
            <DeleteOutlineIcon />
        </button>
    )
}
