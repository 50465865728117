'use client';
import {useState} from 'react';
import HomeContext from './HomeContext';

const HomeProvider = ({children}) => {

    const [sidenavOpen, setSidenavOpen] = useState(false);

    const toggleSidenav = () => {
        console.log('toggleSidenav')
        setSidenavOpen(!sidenavOpen);
    }

    return (
        <HomeContext.Provider
        value={{
            sidenavOpen,
            toggleSidenav
        }}
        >
        {children}
        </HomeContext.Provider>
    );

};

export default HomeProvider;