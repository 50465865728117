import { useState } from "react";
import ProgressBar from "./ProgressBar"
import FormButton from "./buttons/FormButton"

export default function MultiPageForm({ children, label, initialStep, totalSteps, onSubmit }) {
    const [currentStep, setCurrentStep] = useState(initialStep);
    return (
        <div className="flex flex-col w-3/4 mx-auto gap-4 py-4">
            <h1 className="text-2xl">{label}</h1>
            <ProgressBar totalSteps={totalSteps} currentStep={currentStep} setCurrentStep={setCurrentStep} />
            <div className="border border-gray-300 rounded p-4">
                {children.map((child, index) => (
                    <div key={index} className={currentStep === index + 1 ? "block" : "hidden"}>
                        {child}
                    </div>
                ))}
                <div className="flex gap-2 justify-end mt-4">
                    {currentStep > 1 && <FormButton onClick={() => setCurrentStep(currentStep - 1)}>Back</FormButton>}
                    {currentStep < totalSteps && <FormButton onClick={() => setCurrentStep(currentStep + 1)}>Next</FormButton>}
                    {currentStep === totalSteps && <FormButton onClick={onSubmit}>Submit</FormButton>}
                </div>
            </div>
        </div>
    )
}