import { useState } from "react";

export function useColumns() {
    const [columns, setColumns] = useState([
        {
            id: 1,
            field_id: 3,
            label: 'Record ID #',
            width: 150,
            type: 'text',
            shown: true,
            align: 'start',
          },
          {
            id: 2,
            field_id: 8,
            label: 'Assignment Name',
            width: 300,
            type: 'text',
            shown: true,
            align: 'center'
          },
          {
            id: 3,
            field_id: 9,
            label: 'Description',
            width: 400,
            type: 'text',
            shown: true,
            align: 'center'
          },
          {
            id: 4,
            field_id: 10,
            label: 'Due Date',
            width: 200,
            type: 'text',
            shown: true,
            align: 'center'
          },
          {
            id: 5,
            field_id: 11,
            label: 'Priority',
            width: 200,
            type: 'text',
            shown: true,
            align: 'center'
          },
          {
            id: 6,
            field_id: 12,
            label: 'Status',
            width: 250,
            type: 'text',
            shown: true,
            align: 'center'
          },
          {
            id: 7,
            field_id: 13,
            label: 'Start Date',
            width: 200,
            type: 'text',
            shown: true,
            align: 'center'
          },
          {
            id: 8,
            field_id: 14,
            label: 'End Date',
            width: 200,
            type: 'text',
            shown: true,
            align: 'center'
          },
          {
            id: 9,
            field_id: 15,
            label: 'Comments',
            width: 400,
            type: 'text',
            shown: true,
            align: 'center'
          },
    ])
    // The width is the sum of the widths of all the SHOWN columns
    const totalWidth = columns.reduce((acc, curr) => {
        if (curr.shown) {
            return acc + curr.width
        }
        return acc
    }, 0)
    return { columns, setColumns, totalWidth }
}