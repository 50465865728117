export function handleDrop(result, employees, setEmployees) {
    // If the drop is outside of a droppable, do nothing
    if (!result.destination) return;

    // If the source and destination are the same, do nothing
    if (result.source.droppableId === result.destination.droppableId) return;

    // Moving a single item from one crew to another
    // 1. Remove the assignment from the source crew
    // 2. Add the assignment to the destination crew
    // 3. Update the crews state
    const sourceCrew = employees.find(employee => employee['3']['value'] === Number(result.source.droppableId));
    console.log('sourceCrew', sourceCrew);
    const destinationCrew = employees.find(employee => employee['3']['value'] === Number(result.destination.droppableId));
    console.log('destinationCrew', destinationCrew);
    const assignment = sourceCrew['assignments'].find(assignment => assignment['3']['value'] === Number(result.draggableId));
    // Update the source crew
    const sourceAssignmentIndex = sourceCrew['assignments'].findIndex(assignment => assignment['3']['value'] === Number(result.draggableId));
    sourceCrew['assignments'].splice(sourceAssignmentIndex, 1);
    // Update the destination crew
    destinationCrew['assignments'].push(assignment);
    // Update the crews state
    const newCrews = [...employees];
    const sourceCrewIndex = newCrews.findIndex(crew => crew['3']['value'] === sourceCrew['3']['value']);
    const destinationCrewIndex = newCrews.findIndex(crew => crew['3']['value'] === destinationCrew['3']['value']);
    newCrews[sourceCrewIndex] = sourceCrew;
    newCrews[destinationCrewIndex] = destinationCrew;
    setEmployees(newCrews);
    console.log('newCrews', newCrews);
    return;
};