import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import FontProvider from './providers/FontProvider';
import JoyProvider from './providers/JoyProvider';
import { BrowserRouter } from 'react-router-dom'
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <BrowserRouter>
      <FontProvider>
        <JoyProvider>
          <App />
        </JoyProvider>
      </FontProvider>
  </BrowserRouter>
)