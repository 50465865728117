export default function DemoCard({ title, industry, description, preview, onClick }) {
    return (
        <div 
            onClick={onClick}
            className="
                flex 
                flex-col 
                shadow-[0_0_1px_rgba(0,0,0,0.32),0_0_2px_rgba(0,0,0,0.08),0_1px_3px_rgba(0,0,0,0.08)] 
                rounded-md 
                hover:shadow-[0_0_1px_rgba(0,0,0,0.2),0_0_2px_rgba(0,0,0,0.08),0_3px_4px_rgba(0,0,0,0.03),0_3px_16px_rgba(0,0,0,0.06)] 
                cursor-pointer
                "
        >
            <img src={preview} alt={`${title} Preview`} className="w-full h-48 object-cover rounded-t-md border-b border-gray-200" />
            <div className="p-4 flex flex-col">
                <p className="text-xs">{industry}</p>
                <h1 className="my-3 text-[22px] font-semibold">{title}</h1>
                <p className="text-base">
                    {description}
                </p>
            </div>
        </div>
    )
}