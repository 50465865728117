import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Navbar from '../home/components/Navbar';

export default function OnePager() {
    const { onePagerId } = useParams();
    const [fileUrl, setFileUrl] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchOnePager() {
            console.log(`Fetching one pager with ID: ${onePagerId}`);
            try {
                const url = `/files/${onePagerId}`;
                console.log(`Making request to: ${url}`);
                const response = await fetch(url);

                if (response.status === 200) {
                    console.log(`File found for ID: ${onePagerId}`);
                    setFileUrl(url); // Set the URL to the file
                } else {
                    console.error(`No file found for ID: ${onePagerId}`);
                }
            } catch (error) {
                console.error('Fetch error:', error);
            } finally {
                setLoading(false); // Update loading state
            }
        }

        fetchOnePager();
    }, [onePagerId]);

    return (
        <div style={{ height: '100vh', overflow: 'hidden' }}>
            <Navbar />
            {loading ? (
                <p>Loading...</p>
            ) : fileUrl ? (
                <>
                    {console.log(`Displaying file from URL: ${fileUrl}`)}
                    <iframe 
                        src={`/files/${onePagerId}`}
                        style={{ width: '100%', height: 'calc(100vh - 60px)', border: 'none' }} 
                        title="One Pager Content"
                    />
                </>
            ) : (
                <p>No file found for the given ID or loading error occurred.</p>
            )}
        </div>
    );
}
